import React from "react";
import '../CSS/Footer.css';
import {ImgLink} from './Links';
import Gitmark from '../images/github-mark-white.png';
import LinkedinLogo from '../images/LI-In-Bug.png';

export default function Footer() {
    return(
        <div className="footer">
            <div className="gitfoot">
                <ImgLink
                        href={'https://github.com/Gamej18'}
                        src={Gitmark}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        imgalt={'githublogo'}
                        width={26}
                        height={undefined}
                />
                <div className="linkedincard">
                    <ImgLink
                        href={'https://www.linkedin.com/in/jalen-bowens/'}
                        src={LinkedinLogo}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        imgalt={'linkedinlogo'}
                        width={27}
                        height={undefined}
                    />
                </div>
            </div>
        </div>  
    )
}