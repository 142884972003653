import React from "react";
import '../CSS/Links.css';


export function ImgLink({href, src, target, rel, imgalt, width, height}) {
    return (
        <div className="image">
            <a
                href={href}
                target={target}
                rel={rel}>
                <img
                    src={src}
                    alt={imgalt}
                    width={width}
                    height={height}
                />
            </a>
        </div>
    );
}


export function ResumeLink({href, target, rel}) {
    return (
        <div className="res">
            <a
                className="button"
                href={href}
                target={target}
                rel={rel}>
                Resume
            </a>
        </div>
    );
}