import React from "react";
import { FormspreeProvider } from '@formspree/react';
import '../CSS/Contact.css';
import Navigate from "./Navigate";
import Footer from './Footer';
import {person} from './data';
import {keys} from '../Token/keys';
import EmailForm from "./Form";
import {useEffect} from 'react';

export default function Contact() {
    useEffect(() => {
        document.body.classList.add('bg-bluegrey');

        document.body.style.backgroundColor = '#212a32';
    })
    return (
        <div className="contact-container">
             <Navigate />
            <div className="contact-wrap-form">
                <div className="contact-header">
                    <h1>CONTACT ME</h1>
                </div>
                <div className="contact-paragraph">
                    <p>
                        Hi I am open to {person.jobtitle} positions. If you would like to <br/>contact me please leave a message below.
                        If you would like to instead <br/>send me an email through an email link click
                        the email button below.
                    </p>
                </div>
                <FormspreeProvider project={keys.projectkey}>
                    <EmailForm/>
                </FormspreeProvider>
            </div>
            <Footer/>
        </div>
        
    )
}


