import React from "react";
import {useEffect} from 'react';
import "../CSS/Works.css";
import Navigate from './Navigate';
import Footer from './Footer';
import {ImgLink} from "./Links";
import Card from "./Card";
import Gitmark from '../images/github-mark-white.png';

export default function Works() {
    useEffect(() => {
        document.body.classList.add('bg-bluegrey');
        document.body.style.backgroundColor = '#212a32';
    })
    return (
        <div className="work-container">
            <Navigate/>
            <div className="wrap-work-container">
                <Card>
                    <ImgLink
                        href={'https://github.com/Gamej18'}
                        src={Gitmark}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        imgalt={'gitlogo'}
                        width={100}
                        height={100}
                    />
                </Card>
            </div>
            <Footer/>
        </div>
    );
} 
