import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import About from './Route/About';
import Contact from './Route/Contact';
import AppInfo from './Route/AppInfo';
import Works from './Route/Works';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Routes>
        <Route path="/" element={<AppInfo />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About/>} />
          <Route path="/Works" element={<Works/>} />
      </Routes>
    </Router>
  
);

