import React from "react";
import { Link } from "react-router-dom";
import "../CSS/Navigate.css";

export default function Navigate() {
  return (
    <div className="mainmenu">
      <nav>
        <ul>
          <li><Link className="HomeLink" to="/">Home</Link></li>
          <li><Link className="Work" to ="/Works">Work</Link></li>
          <li><Link className="About" to ="/About">About</Link></li>
          <li><Link className="Contact" to ="/Contact">Contact</Link></li>
        </ul>
      </nav>
    </div>     
  )
}
