import React from "react";
import '../CSS/About.css';
import Navigate from './Navigate';
import Footer from './Footer';
import {ResumeLink} from "./Links";
import {person} from './data';
import {useEffect} from 'react';



export default function About() {
    useEffect(() => {
        document.body.classList.add('bg-bluegrey');
        document.body.style.backgroundColor = '#212a32';
    })
    return (
        <div className="about">
            <Navigate />
            <div className="abody">
                <div className="information">
                    <div className="name">
                        <h5>About Me</h5>
                        {/* <h3>Who I am</h3> */}
                    </div>
                    
                    <div className="intro">
                        <p>Hi, I'm {person.name} I am a {person.jobtitle}
                             with a Bachelors Degree in Computer Science. 
                            I am quickly gearing myself for imployment in a front-end
                            development position. Making this website as well as
                            other websites and application to show employers for review. The 
                            website needed to be clean and lightweight. It as well needed to
                            reflect what I can do and have been learning. This is a project
                            that will consistantly be updated as improvements are made and the
                            direction of the website changes. Please take a look around and you can
                            contact me by going to the contact page and filling out the form or by clicking the email link at the bottom
                            of the screen. You can also click the link below to view my Resume.
                        </p>
                        <div className="resume">
                            <ResumeLink
                                href={'https://drive.google.com/file/d/1SATGTmh_oxtccigTyg_Hwa6H4bOTVi8P/view?usp=share_link'}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            />
                        </div>
                    </div>
                </div>
                <div className="study">
                    <ol>
                        <li>Name: Jalen Bowens</li>
                        <li>Phone: 615-821-7386</li>
                        <li>Experience: 1 year</li>
                        <li>Age: 22</li>
                        <li>Address: Tennessee, USA</li>
                        <li>Full-Time: Avaiable</li>
                        <li>Email: jalenbowens9@gmail.com</li>
                    </ol>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


