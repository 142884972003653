import React from 'react';
import "../CSS/AppInfo.css";
import Footer from './Footer';
import Navigate from './Navigate';
import {person} from './data';
import {useEffect} from 'react';

export default function AppInfo() {
    useEffect(() => {
        document.body.classList.add('bg-bluegrey');
        document.body.style.backgroundColor = '#212a32';
    })
    return (
        <div className="main-container">
            <div>
                <Navigate />
                <div className="main-wrap-form">
                    <div className="info-container">
                        <p aria-label='name Jalen'>Hey, I'm {person.name}</p>
                        <p aria-label='job title software developer'>I'm a {person.jobtitle}</p>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    ) 
}



