import React from "react";
import { useForm } from '@formspree/react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function EmailForm() {
    const [state, handleSubmit] = useForm("contact");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    return (
        <div className="form">
            <Form className="mt-5 m-5" action="https://formspree.io/p/2112076332128009432/f/contact" method="POST" onSubmit={handleSubmit}>
                <Row className="mb-3 justify-content-center">
                    <Form.Group className="col-md-3 mb-3 ">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="Email" name="email" id="userEmail" aria-label="User Email Input" placeholder="example@email.com" required/>
                    </Form.Group> 
                    <Form.Group className="col-md-3 mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" id="userName" aria-label="User Name Input" placeholder="name" required/>
                    </Form.Group>
                </Row>
                <Row className="justify-content-center row-md-3">
                    <Form.Group className="col-md-6 mb-5">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" name="subject" id="userSubject" aria-label="User Name Input" placeholder="Subject" required/>
                    </Form.Group>
                    <Col className="justify-content-center">
                        <Form.Group className="col-md-6">
                            <Form.Label>Send a Message</Form.Label>
                            <textarea class="form-control" name="message" id="textMessage" rows="6" aria-label="User Text Input" placeholder="Message"></textarea>
                        </Form.Group>
                    </Col>
                    <Button type="Submit" disabled={state.submitting} variant="btn btn-primary col-md-1 mt-5">Submit</Button>
                </Row>
            </Form>
        </div>
    )
}